import Vue from 'vue'
import Router from 'vue-router'

import store from '@/store/index.js'

import { AmplifyEventBus } from 'aws-amplify-vue'
import * as AmplifyModules from 'aws-amplify'
import { AmplifyPlugin } from 'aws-amplify-vue'

Vue.use(Router)
Vue.use(AmplifyPlugin, AmplifyModules)

let user

getUser().then((user) => {
  if (user) {
    router.push({path: '/'});
  }
})

function getUser() {
  return Vue.prototype.$Amplify.Auth.currentAuthenticatedUser().then((data) => {
    if (data && data.signInUserSession) {
      store.commit('setUser', data)
      return data;
    }
  }).catch(() => {
    store.commit('setUser', null)
    return null
  })
}

AmplifyEventBus.$on('authState', async (state) => {
  if (state === 'signedOut'){
    user = null;
    store.commit('setUser', null);
    router.push({path: '/signIn'}, () => {})
  } else if (state === 'signedIn') {
    user = await getUser();
    router.push({path: '/'})
  }
})

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "NavBar" */ '../views/NavBar.vue'),
    children: [
      {
        path: '',
        name: 'Home',
        component: () => import(/* webpackChunkName: "Home" */ '../views/Home.vue'),
        meta: { requireAuth: true }
      }
    ]
  },
  {
    path: '/signIn',
    name: 'SignIn',
    component: () => import(/* webpackChunkName: "SignIn" */ '../views/SignIn.vue'),
  },
  {
    path: '/map',
    component: () => import(/* webpackChunkName: "MapMenu" */ '../views/MapMenu.vue'),
    children: [
      {
        path: '',
        name: 'Map',
        component: () => import(/* webpackChunkName: "GoogleMap" */ '../views/GoogleMap.vue'),
        meta: { requireAuth: true }
      }
    ],
  },
  {
    path: '/device',
    component: () => import(/* webpackChunkName: "NavBar" */ '../views/NavBar.vue'),
    children: [
      {
        path: '',
        name: 'Device',
        component: () => import(/* webpackChunkName: "ListDevice" */ '../views/ListDevice.vue'),
        meta: { requireAuth: true }
      }
    ],
  },
  {
    path: '/assign',
    component: () => import(/* webpackChunkName: "NavBar" */ '../views/NavBar.vue'),
    children: [
      {
        path: '',
        name: 'Assign',
        component: () => import(/* webpackChunkName: "Assign" */ '../views/Assign.vue'),
        meta: { requireAuth: true }
      }
    ],
  },
  {
    path: '/camerasetting',
    component: () => import(/* webpackChunkName: "NavBar" */ '../views/NavBar.vue'),
    children: [
      {
        path: '',
        name: 'CameraSetting',
        component: () => import(/* webpackChunkName: "CameraSetting" */ '../views/CameraSetting.vue'),
        meta: { requireAuth: true }
      }
    ],
  },
  {
    path: '/admindevice',
    component: () => import(/* webpackChunkName: "NavBar" */ '../views/NavBar.vue'),
    children: [
      {
        path: '',
        name: 'AdminDevice',
        component: () => import(/* webpackChunkName: "CameraSetting" */ '../views/AdminDevice.vue'),
        meta: { requireAuth: true }
      }
    ],
  },
]

const router = new Router({
  mode: 'history',
  routes
})

router.beforeResolve(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requireAuth)) {
    user = await getUser()
    if (!user) {
      return next({
        path: '/signIn'
      })
    }
    return next()
  }
  return next()
})

export default router
