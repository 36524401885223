<template>
  <v-app>
    <router-view />
  </v-app>
</template>

<script>
window.LOG_LEVEL = "VERBOSE";

export default {
  name: "app",
  data() {
    return {};
  },
};
</script>
