import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

import Amplify, * as AmplifyModules from 'aws-amplify'
import { AmplifyPlugin } from 'aws-amplify-vue'
import awsconfig from './aws-exports'
import "@aws-amplify/ui-vue";
//import { components } from 'aws-amplify-vue'
import { Auth } from 'aws-amplify'


Amplify.configure(Object.assign({}, awsconfig, {
  API: {
    graphql_headers: async ()=>({
      Authorization: (await Auth.currentSession()).getIdToken().getJwtToken()
    })
  },
}))

Amplify.configure(awsconfig)

Vue.use(AmplifyPlugin, AmplifyModules)

Vue.config.productionTip = false

// 多言語対応
let languageDict = {
  ja:{
      // タイトル
      'Sign in to your account' : 'サインイン',
      'Create a new account': 'アカウントの新規作成',
      'Reset your password': 'パスワードをリセット',
      'Confirm Sign Up': 'メールアドレスの認証',
      // 入力項目
      'Username' : 'ユーザーネーム',
      'Enter your Username' : 'ユーザーネームを入力してください',
      'Email' : 'メールアドレス',
      'Enter your Email' : 'メールアドレスを入力してください',
      'Name' : '名前',
      'Password' : 'パスワード',
      'Enter your password' : 'パスワードを入力してください',
      'Code': '認証コード',
      'New Password': '新しいパスワード',
      'Confirmation Code': '認証コード',
      // 注釈
      'Forgot your password? ' : 'パスワードを忘れた場合は ',
      'No account? ' : 'アカウントの作成は ',
      'Have an account? ': 'サインインは ', 
      'Lost your code? ': 'メールアドレスに認証コードが届きませんか? ', 
      // ボタン
      'Back to Sign In': 'サインイン',
      'Resend Code': '認証コードを再送信する',
      'Reset password' : 'パスワードを再設定',
      'Sign in' : 'こちら',
      'Sign In' : 'サインイン',
      'Sign Out' : 'サインアウト',
      'Create account' : 'こちら',
      'Create Account' : 'アカウントを作成',
      'Send Code': '次へ進む',
      'Submit': '送信',
      'Confirm': '登録完了',
      // エラーコード
      'Username cannot be empty': 'ユーザーネームを入力してください',
      "Custom auth lambda trigger is not configured for the user pool.": "パスワードが入力されていません",
      'null failed with error Generate callenges lambda cannot be called..': 'パスワードを入力してください',
      'Incorrect username or password.': 'メールアドレス もしくは パスワードが正しくありません',
      'Username/client id combination not found.': 'メールアドレスが正しくありません',
      'Invalid verification code provided, please try again.': '認証コードが正しくありません',
      'Password reset required for the user': 'パスワードを再設定してください'
  }
}
AmplifyModules.I18n.putVocabularies(languageDict)

new Vue({
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount('#app')
